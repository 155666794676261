<!-- 新冠报告 -->
<template>
    <div style="padding:20px 24px 40px 24px">
        <div class="globalText" style="margin-bottom: 30px;">
            本报告针对新冠病毒（SARS-CoV-2）特异性抗体（IgM+IgG+中和抗体）与T细胞（免疫指纹）进行分析，评价现阶段抵抗新冠病毒感染的能力。</div>
        <div class="globalTitle" style="margin-bottom: 20px;color: #865BFF;">1、新冠特异性抗体检测</div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top" @click="goto(1)">
                <span style="color: #FFFFFF;">新冠特异性IgM抗体</span>
                <span style="color: #FFFFFF;font-size: 12px;">了解更多<van-icon name="arrow" color="#FFFFFF"
                        size="14px" /></span>
            </div>
            <div class="content">
                <div style="display:flex;">
                    <div class="tip">检测内容</div>
                    <div class="value">IgM</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">检测结果<br />(OD值)</div>
                    <div class="value">{{ report.igmOD }}</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">判断结果</div>
                    <div class="value">{{ report.igmres }}</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">参考范围</div>
                    <div class="value">OD值＞0.15（阳性）<br />OD值≤0.15（阴性）</div>
                </div>
            </div>
        </div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top" @click="goto(2)">
                <span style="color: #FFFFFF;">新冠特异性IgG抗体</span>
                <span style="color: #FFFFFF;font-size: 12px;">了解更多<van-icon name="arrow" color="#FFFFFF"
                        size="14px" /></span>
            </div>
            <div class="content">
                <div style="display:flex;">
                    <div class="tip">检测内容</div>
                    <div class="value">IgG</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">检测结果<br />(OD值)</div>
                    <div class="value">{{ report.iggOD }}</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">判断结果</div>
                    <div class="value">{{ report.iggres }}</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">参考范围</div>
                    <div class="value">OD值＞0.15（阳性）<br />OD值≤0.15（阴性）</div>
                </div>
            </div>
        </div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top" @click="goto(3)">
                <span style="color: #FFFFFF;">新冠中和抗体</span>
                <span style="color: #FFFFFF;font-size: 12px;">了解更多<van-icon name="arrow" color="#FFFFFF"
                        size="14px" /></span>
            </div>
            <div class="content">
                <div style="display:flex;">
                    <div class="tip">检测内容</div>
                    <div class="value">中和抗体</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">检测结果<br />(浓度值[IU/ml])</div>
                    <div class="value">{{ report.antibodyOD }}</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">判断结果</div>
                    <div class="value">{{ report.antibodyRes }}</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">参考范围</div>
                    <div class="value">浓度值＞80（阳性）<br />浓度值≤80（阴性）</div>
                </div>
            </div>
        </div>


        <div class="resultItem">
            <div class="globalTitle" style="color: #865BFF;">2、新冠特异性T细胞检测—T细胞免疫指纹</div>
            <div style="margin:12px 0 16px 0;">
                <span class="globalText middle" style="line-height: 30px;">共检出新型冠状病毒（SARS-CoV-2）特异性TCR克隆<span
                        style="color:#865BFF;margin:0 5px;">{{ report.covid19Num }}</span>个</span>

            </div>
            <div style="margin:12px 0 16px 0;">
                <span class="globalText middle" style="line-height: 30px;">即体内存在<span style="color:#865BFF;margin:0 5px;">{{
                    report.covid19Num }}</span>种新冠特异性T细胞</span>
                <!-- <span class="index middle">1887</span> -->

            </div>
            <div class="resultButton" @click="goto(4)">
                <img :src="ossUrl + 'btn1.png'" alt="" class="btnBgc">
                <span class="globalText btnText">新冠特异性T细胞详解</span>
            </div>
        </div>
    </div>
</template>
<script>
const ossUrl = 'https://kindstarbiotech.oss-cn-hangzhou.aliyuncs.com/rosefinch/static/h5/'
export default {
    data() {
        return {
            ossUrl: ossUrl,
            report: {}
        }
    },
    created() {
    },
    mounted() {
        this.report = JSON.parse(localStorage.getItem('report'))
        let scrollTop = localStorage.getItem('st')
        if (scrollTop != null && scrollTop != 'undefined') {
            window.scroll({ top: scrollTop, left: 0 });
        } else {
            window.scroll({ top: 0, left: 0 })
        }
    },
    computed: {
    },
    methods: {
        // 记录滚动信息
        getScrollTop() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            localStorage.setItem('st', scrollTop)
        },
        goto(index) {
            this.getScrollTop()
            switch (index) {
                case 1:
                    this.$router.push('/report/sarsIgM')
                    break;
                case 2:
                    this.$router.push('/report/sarsIgG')
                    break;
                case 3:
                    this.$router.push("/report/sarsNeutralizing")
                    break;
                case 4:
                    this.$router.push("/report/sarsImmunofingerprint")
                    break
                case 5:
                    this.$router.push("/reference")
                    break
            }
        }
    },
}
</script>
<style lang="scss" scoped>
@import url(../../../static/css/global.css);

.top {
    width: 100%;
    height: 40px;
    background: #926BFF;
    border-radius: 8px 8px 0px 0px;
    padding: 9px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content {
    width: 100%;

    .tip {
        width: 42%;
        background: #FAFCFF;
        padding: 9px 12px;
        box-sizing: border-box;
        border: 1px solid #E1E3E6;
        border-right: none;
        margin-top: -1px;
        display: flex;
        align-items: center;
        text-align: left;
    }

    .value {
        // width: 191px;
        flex: 1;
        background: #FAFCFF;
        padding: 9px 12px;
        box-sizing: border-box;
        border: 1px solid #E1E3E6;
        margin-top: -1px;
        display: flex;
        align-items: center;
    }
}

.resultItem {
    width: 100%;
    padding: 20px 16px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin-top: 30px;
}

.resultButton {
    width: 100%;
    position: relative;

    .btnBgc {
        width: 100%;
        display: block;
    }

    .btnText {
        position: absolute;
        left: 24px;
        top: 50%;
        transform: translateY(-50%);
        color: #FFFFFF
    }
}
</style>
